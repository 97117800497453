.modal {
    :global(.ant-modal-content) {
        padding: 0;
      }
      :global(.ant-modal-header) {
        box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
        padding: 16px;
      }
      :global(.ant-modal-body) {
        padding: 16px;
      }
      :global(.ant-modal-footer) {
        box-shadow: 0px 1px 0px 0px #F0F0F0 inset;
        padding: 16px;
      }
}