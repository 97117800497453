.aerotable {
    :global(.ant-table-cell) {
        padding: 0 !important;
    }   
    :global(.ant-table-content) {
        max-height: calc(100vh - 432px);
        overflow-y: auto !important;
    }
    :global(.ant-table-thead) {
        z-index: 20;
        overflow-y: auto;
        position: sticky;
        top: 0;
        bottom: 0;
    }
}