.card {
  :global(.ant-card-head) {
    padding: 0;
  }
  :global(.ant-tabs-nav-list) {
    width: 100%;
  }
  :global(.ant-tabs-tab) {
    width: 50%;
    display: flex;
    justify-content: center;
  }
}
