.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.header {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 75px;
}

.footer {
  position: absolute;
  bottom: 1rem;
  left: 0px;
  right: 0px;
}

@media screen and (max-height: 730px) {
  .wrapper {
    justify-content: space-between;
  }
  .header {
    margin-top: 75px;
    position: relative;
    top: 0;
  }

  .footer {
    position: relative;
    bottom: 0;
  }
}
